'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import Script from 'next/script';
import { useEffect, Suspense } from 'react';
import { isProd } from '@/utils/settings';

declare global {
  interface Window {
    gtag?: (...args: any[]) => void;
  }
}

export const GOOGLE_ANALYTICS_ID = 'G-3QCPKQV3HW';

export const PageViewAnalyticsCore = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (!pathname) {
      console.log(`[Analytics Debug] PageView not tracked - no pathname:`, { pathname, searchParams: searchParams.toString() });
      return;
    }

    if (!isProd) {
      console.log(`[Analytics Debug] PageView not tracked in non-production:`, { pathname, searchParams: searchParams.toString() });
      return;
    }

    const url = searchParams.toString()
      ? `${pathname}?${searchParams.toString()}`
      : pathname;

    window.gtag?.('config', GOOGLE_ANALYTICS_ID, {
      page_path: url,
      anonymize_ip: true,
    });
  }, [pathname, searchParams]);

  return null;
};

export const PageViewAnalytics = () => {
  return (
    <Suspense fallback={null}>
      <PageViewAnalyticsCore />
    </Suspense>
  );
};

export const GoogleAnalyticsScript = () => {
  return (
    <>
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}
      />
      <Script id="google-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GOOGLE_ANALYTICS_ID}', {
            'anonymize_ip': true
          });
        `}
      </Script>
    </>
  );
};

export enum EVENT {
  CLICK = 'click',
  SUBMIT_FORM = 'submit_form',
  REACH_END_OF_PAGE = 'reach_end_of_page',
  MANUALLY_EDIT_TEXT_ONCE = 'manually_edit_text_once',
}

export enum PAGE {
  LANDING = 'Landing Page',
}

export enum COMPONENT {
  PROMPT_DEMO = 'Prompt Demo',
}

export const TRACK = {
  EVENT,
  PAGE,
  COMPONENT,
};

export const trackEvent = (
  event: EVENT,
  {
    page,
    component,
    label,
    value,
  }: {
    page: PAGE; // The page where the event occurred
    component?: COMPONENT; // Optional component triggering the event
    label?: string; // Optional label for dynamic names (e.g., "Sign Up")
    value?: number; // Optional numeric value (e.g., engagement score)
  }
): void => {
  if (!page) {
    console.warn('page is required for tracking events');
    return;
  }
  
  const eventData: Record<string, any> = {
    page,
    ...Object.fromEntries(
      Object.entries({ component, label, value })
        .filter(([_, value]) => value !== undefined)
    )
  };

  if (!isProd) {
    console.log(`[Analytics Debug] Event not tracked in non-production:`, { event, ...eventData });
    return;
  }

  if (typeof window !== 'undefined' && window.gtag) {
    window.gtag('event', event, eventData);
  } else {
    console.warn(`gtag is not defined. Event not tracked: ${event}`, eventData);
  }
};
